.Recipe {
    background-image: url('https://images.unsplash.com/photo-1547516508-4c1f9c7c4ec3?ixlib=rb-1.2.1&auto=format&fit=crop&w=3459&q=80');
    background-color: transparent;
    background-size: cover;
    height: 100vh;
    opacity: 0.2;
}

.recipeTitle {
    opacity: 1;
}